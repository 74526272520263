import React, { Fragment, useEffect } from 'react'
import moment from 'moment'
// import DateTimeInput from 'aor-datetime-input'
import { get, find } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'

import PreResaStatsCard from './preResaStatsCard'
import { CustomDatePicker } from '../../components/CustomDatePickers'
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  Pagination,
  Responsive,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin'
import { countries, parkList, preResaUsers, reasonList, requestProcessed, requestStates } from '../constants'
import BulkDeleteConfirmButton from './BulkDeleteConfirmButton'

//
// --------------- FORMATEURS POUR LES FORMULAIRES --------------------
// formateurs pour les listes

const DateOnlyField = props => (
  <span>
    {get(props.record, props.source)
      ? moment
          .utc(get(props.record, props.source))
          .local()
          .format('YYYY-MM-DD')
      : ''}
  </span>
)
const DateHourField = props => (
  <span>
    {get(props.record, props.source)
      ? moment
          .utc(get(props.record, props.source))
          .local()
          .format('YYYY-MM-DD HH:mm:ss')
      : ''}
  </span>
)
DateHourField.defaultProps = { addLabel: true }

const ValueListField = props => {
  const mySource = get(props.record, props.source)
  const oLabel = find(props.valueList, { id: mySource })
  if (mySource && oLabel && oLabel.name) {
    return <span>{oLabel.name}</span>
  } else return <span>{}</span>
}

const LimitedTextField = ({ source, record, maxLength }) => {
  if (get(record, source) && maxLength < get(record, source).length) {
    return <span>{`${get(record, source).substr(0, maxLength)}...`}</span>
  }
  return <span>{get(record, source)}</span>
}

const PreResaFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <AutocompleteInput source="country" label="Pays" choices={countries} allowEmpty={false} />
    <AutocompleteInput source="park_id" label="Lieu du séjour" choices={parkList} allowEmpty />
    <SelectInput source="request_processed" label="Statut de la fiche" choices={requestProcessed} />
    <BooleanInput source="request_state.$not.$exists" label="Etat demande VIDE?" />
    <SelectInput source="request_state" label="Etat de la demande" choices={requestStates} allowEmpty />
    <SelectInput source="processed_by" label="Demande traitée par" choices={preResaUsers} />
    <SelectInput source="reason" label="Raison du refus" choices={reasonList} />
    <CustomDatePicker source="created_at_before" label="Date de demande avant le (≤) :" />
    <CustomDatePicker source="created_at_after" label="Date de demande après le (≥) :" />
  </Filter>
)

const PostBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteConfirmButton {...props} undoable={false} />

    {/* Add the default bulk delete action <BulkDeleteButton {...props} /> */}
  </Fragment>
)

const useListStyles = makeStyles({
  main: {
    flexDirection: 'column-reverse',
  },
  bulkActionsDisplayed: {
    backgroundColor: 'yellow',
  },
})
const useDGStyles = makeStyles({
  thead: {
    backgroundColor: '#ccc',
  },
})

export const PreResaList = props => {
  useEffect(() => {
    document.title = 'Demandes de réservations - Homair Backend Administration'
  }, [])

  const listClasses = useListStyles()
  const dgClasses = useDGStyles()

  const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} {...props} />

  return (
    <div>
      <List
        {...props}
        filters={<PreResaFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        title="Liste des demandes de réservations"
        bulkActionButtons={<PostBulkActionButtons />}
        pagination={<ListPagination />}
        aside={<PreResaStatsCard {...props} />}
        classes={listClasses}
        component="div"
      >
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.firstname}
              secondaryText={record => `${record.lastname}`}
              tertiaryText={record => <DateOnlyField source="created_at" record={record} />}
            />
          }
          medium={
            <Datagrid /*bodyOptions={{ stripedRows: true, showRowHover: true }}*/ classes={dgClasses}>
              <TextField source="lastname" label="Nom" />
              <TextField source="firstname" label="Prénom" />
              <ValueListField source="country" label="Pays" valueList={countries} />
              <ValueListField source="park_id" valueList={parkList} label="Lieu du séjour" />
              <DateOnlyField source="call_back_at" label="A rappeler" />
              <ValueListField source="request_state" valueList={requestStates} label="Etat de la demande" />
              <ValueListField source="reason" valueList={reasonList} label="Raison" />
              <ValueListField source="request_processed" valueList={requestProcessed} label="Statut de la fiche" />
              <DateHourField source="created_at" label="Ajoutée le" />
              <LimitedTextField source="hv_comments" label="Commentaire" maxLength="50" />
              <EditButton />
            </Datagrid>
          }
        />
      </List>
    </div>
  )
}
