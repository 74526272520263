import { get } from "lodash";

const TagsListField = (props) => (
  get(props.record, props.source,[]).join(', ')
)
TagsListField.defaultProps = {
  addLabel: true
};

export default TagsListField
