import config from './config'

const authProvider = {
  // called when the user attempts to log in
  login: params => {
    console.log(params, '=========================================')
    const { username, password } = params
    const request = new Request(`${config.backend_url}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ user }) => {
        console.log('User logged-in ! user=', user)
        localStorage.setItem('user', JSON.stringify(user))
        return user
      })
  },

  // called when the user clicks on the logout button
  logout: params => {
    console.log('User logged-out !')
    localStorage.removeItem('user')
    return Promise.resolve()
  },

  // called when the API returns an error
  checkError: params => {
    const { status } = params
    // const u = localStorage.getItem('user')
    // console.log('AUTH_ERROR', JSON.parse(u), !!u, status, params)

    if (status === 401 || status === 403) {
      console.error('401 not authorized')
      localStorage.removeItem('user')
      return Promise.reject()
      // return Promise.resolve()
    }
    return Promise.resolve()
  },

  // called when the user navigates to a new location
  checkAuth: params => {
    const u = localStorage.getItem('user')
    // console.log('auth_check', JSON.parse(u), !!u, params)
    return u ? Promise.resolve() : Promise.reject({ redirectTo: '/login' })
  },

  // by default, allow this :
  getPermissions: params => {
    return Promise.resolve()
  },
}

export default authProvider
