import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  Filter,
  List,
  ListButton,
  Pagination,
  Responsive,
  SelectArrayInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import moment from 'moment'
import { getUser } from '../App'
import TagsListField from '../components/TagsListField'

export function hasPermission(permission) {
  let user = getUser()
  let allowed = false
  if (user) {
    allowed = user.permissions.indexOf(permission) !== -1
  }

  return allowed
}

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const formatDateField = WrappedComponent => props => {
  // console.log('truc=', props.record[props.source], props.record.username)
  const newProps = Object.assign({}, props, {
    [props.source]: moment.utc(props.record[props.source]).format('YY-MM-DD HH:mm:ss'),
  })
  //  console.log(newProps[props.source])

  return <WrappedComponent {...newProps} />
}

const DateField = formatDateField(TextField)

export const UserList = props => {
  const translate = useTranslate()
  const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} {...props} />

  return (
    <List {...props} filters={<UserFilter />} title={translate('hv.user.list.title')} pagination={<ListPagination />}>
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.username}
            secondaryText={record => `${record.email} views`}
            tertiaryText={record => new Date(record.joined_at).toLocaleDateString()}
          />
        }
        medium={
          <Datagrid /* bodyOptions={{ stripedRows: true, showRowHover: true }}*/>
            <TextField source="username" />
            <EmailField source="email" />
            <DateField source="joined_at" />
            <DateField source="last_login" />
            <DateField source="last_api_usage" label="Last API usage"/>
            <DateField source="expires" label="Token expiration" />
            <BooleanField source="enabled" />
            <TagsListField source="scopes" />
            <TagsListField source="permissions" />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  )
}

const UserTitle = ({ record }) => <span>User {record ? `"${record.username}"` : ''}</span>

const scopes = [
  { id: 'apicontent', name: 'Content API' },
  { id: 'apiprice', name: 'Prices API' },
]
const permissions = [
  { id: 'ROLE_PRICE_SIMPLE', name: 'ROLE_PRICE_SIMPLE' },
  { id: 'ROLE_PRICE_FULL', name: 'ROLE_PRICE_FULL' },
  { id: 'ROLE_READ_CONTENT', name: 'ROLE_READ_CONTENT' },
  { id: 'ROLE_WRITE_CONTENT', name: 'ROLE_WRITE_CONTENT' },
  { id: 'ROLE_READ_PREBOOKING', name: 'ROLE_READ_PREBOOKING' },
  { id: 'ROLE_WRITE_PREBOOKING', name: 'ROLE_WRITE_PREBOOKING' },
  { id: 'ROLE_MANAGE_USERS', name: 'ROLE_MANAGE_USERS' },
  { id: 'ROLE_READ_CONSENT', name: 'ROLE_READ_CONSENT' },
  { id: 'ROLE_WRITE_CONSENT', name: 'ROLE_WRITE_CONSENT' },
  { id: 'ROLE_READ_ALERT', name: 'ROLE_READ_ALERT' },
  { id: 'ROLE_WRITE_ALERT', name: 'ROLE_WRITE_ALERT' },
  { id: 'ROLE_SEO_MANAGER', name: 'ROLE_SEO_MANAGER' },
]

const EditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
)

const TokenCmdHelper = ({ source, record = {}, label }) => {
  const cmd = 'node src/get_token.js ' +
    moment(record.expires).diff(moment(), 'days') + ' ' +
    record.username + ' ' + record.secretPhrase + ' ' + record.email+ ' ' +
    (record.scopes ? record.scopes.join('|') : 'apicontent') + ' ' +
    (record.permissions ? record.permissions.join('|') : '')
  return <div class="info-field"><label>{label}</label><textarea readOnly style={{'width':'100%'}} col="150" rows="2" value={cmd}></textarea></div>
}
const ShowRecordValue = ({record, source}) => (<div class="info-field"><label class="">{source}</label><div class="info-field-content">{record[source]}</div></div>)

export const UserEdit = props => (
  <Edit title={<UserTitle />} actions={<EditActions />} undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="username" />
      <BooleanInput source="enabled" />
      <TextInput source="password" type="password" />
      <TextInput source="email" />
      <TextInput source="secretPhrase" />
      <SelectArrayInput source="scopes" choices={scopes} options={{ multiple: true }} />
      <SelectArrayInput source="permissions" choices={permissions} options={{ multiple: true }} />
      <ShowRecordValue source="_id"  />
      <ShowRecordValue source="joined_at"  />
      <ShowRecordValue source="last_login"  />
      <ShowRecordValue source="last_api_usage"  />
      <ShowRecordValue source="expires"  />
      <TokenCmdHelper label="Command to generate the token" />
    </SimpleForm>
  </Edit>
)


export const UserCreate = props => (
  <Create actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput source="username" />
      <BooleanInput source="enabled" />
      <TextInput source="email" />
      <TextInput source="password" type="password" />
      <TextInput source="secretPhrase" />
      <SelectArrayInput source="scopes" choices={scopes} options={{ multiple: true }} />
      <SelectArrayInput source="permissions" choices={permissions} options={{ multiple: true }} />
    </SimpleForm>
  </Create>
)
