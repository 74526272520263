// in src/App.js
import React from 'react'
import { fetchUtils, Admin, Resource } from 'react-admin'
import simpleRestClient from 'ra-data-simple-rest'

import PreResaIcon from '@material-ui/icons/Schedule'
import UserIcon from '@material-ui/icons/Group'
import authProvider from './authProvider'
import customRoutes from './customRoutes'
import LoginPage from './Login'
import config from './config'

import Dashboard from './modules/dashboard'
import { UserList, UserCreate, UserEdit, hasPermission } from './modules/users'
import { PreResaList } from './modules/preresa/preResa'
import { PreResaEdit, PreResaCreate } from './modules/preresa/preResaForm'
import i18nProvider from './i18nProvider'
import { ConsentShow, ConsentList } from './modules/consent'
import ConsentIcon from '@material-ui/icons/VerifiedUser'
import { AlertShow, AlertList } from './modules/alerts/alerts'
import AlertIcon from '@material-ui/icons/Alarm'
import { createTheme } from '@material-ui/core/styles'
import { blue, red } from '@material-ui/core/colors'

const myTheme = createTheme({
  palette: {
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    secondary: blue,
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: 'white !important', // Some CSS
        marginRight: 20,
      },
    },
  },
  sidebar: {
    width: 180, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
})

export function getUser() {
  const userString = localStorage.getItem('user')
  if (userString) {
    let user
    try {
      user = JSON.parse(userString)
    } catch (e) {
      console.error('getUser:', e)
      user = {}
    }
    return user
  }
}

//
// HttpClient that forward the token during each API calls.
//
export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const user = getUser()
  if (user && user.jwt) {
    options.headers.set('x-access-token', user.jwt.token)
  } else {
    localStorage.removeItem('user')
  }

  return fetchUtils.fetchJson(url, options)
}

export const restClient = simpleRestClient(config.backend_url, httpClient)

const App = () => (
  <Admin
    theme={myTheme}
    title="Homair Backend Administration"
    loginPage={LoginPage}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={restClient}
    // restClient={jsonServerRestClient('https://jsonplaceholder.typicode.com')}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
    // customReducers={{ preResaFilterReducer }} // pour passer à tous les coups dans ce reducer (utile pour les logs)
  >
    {(permissions) => [
      <Resource
        name="preresa"
        options={{ label: 'Demandes de résa' }}
        icon={PreResaIcon}
        list={PreResaList}
        edit={PreResaEdit}
        create={PreResaCreate}
      />,
      hasPermission('ROLE_READ_CONSENT') ? (
        <Resource name="consent" options={{ label: 'Consentements' }} icon={ConsentIcon} list={ConsentList} show={ConsentShow} />
      ) : null,
      hasPermission('ROLE_MANAGE_USERS') ? (
        <Resource name="users" options={{ label: 'Users' }} icon={UserIcon} list={UserList} edit={UserEdit} create={UserCreate} />
      ) : null,
      hasPermission('ROLE_READ_ALERT') ? (
        <Resource name="api/alert" options={{ label: 'Alerts' }} icon={AlertIcon} list={AlertList} show={AlertShow} />
      ) : null,
    ]}
  </Admin>
)

export default App
