import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const Dashboard = props => (
  <Card style={{ margin: '2em' }}>
    <CardHeader title="Bienvenue dans l'interface d'administration HOMAIR" />
    <CardContent>Utilisez le menu de gauche pour créer ou modifier les droits des utilisateurs, et gérer les demandes de réservations.</CardContent>
  </Card>
)

export default Dashboard
