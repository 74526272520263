// in src/comments/JsonViewerButton.js
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PageViewIcon from '@material-ui/icons/Pageview'
import Button from '@material-ui/core/Button'
import config from '../../config'
import { getUser } from '../../App'
import Tooltip from '@material-ui/core/Tooltip'

class JsonViewerButton extends Component {
  handleClick = () => {
    const { record } = this.props

    const user = getUser()
    const url = config.backend_url + '/api/alert/' + record.id + '?access_token=' + user.jwt.token

    window.open(url)
  }

  render() {
    return (
      <Tooltip title="Show the raw alert">
        <Button onClick={this.handleClick}>
          <PageViewIcon color="primary" />
        </Button>
      </Tooltip>
    )
  }
}

JsonViewerButton.propTypes = {
  record: PropTypes.object,
}

export default connect()(JsonViewerButton)
