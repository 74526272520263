let config = {
  backend_url: process.env.REACT_APP_API_URL || 'https://apicontent-dev.homair.com',
}

// if (process.env.WEBPACK_NODE_ENV === 'production') {
//   config = {
//     backend_url: 'https://apicontent.homair.com',
//     // backend_url: 'https://apicontent-staging.homair.com'
//   }
// } else if (process.env.WEBPACK_NODE_ENV === 'staging') {
//   config = {
//     backend_url: 'https://apicontent-staging.homair.com',
//   }
// }

export default config
