import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { parse } from 'query-string'

const GetToken = props => {
  const q = parse(props.location.search)

  if (q.u) {
    // console.log(q.u)
    const hasLocalStorageSupport = typeof window !== 'undefined' && 'localStorage' in window && window.localStorage !== null
    hasLocalStorageSupport && window.localStorage.setItem('user', q.u)

    // return <Redirect to="/" />
    window.location.href = window.location.origin
  }
  //   // Parse query params.
  // parse()

  return <Redirect to="/login" />
}

export default [<Route exact path="/save-auth" render={GetToken} />]
