import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from './i18n/fr.js'
import englishMessages from './i18n/en.js'

const i18nProvider = polyglotI18nProvider(
  locale => (locale === 'fr' ? frenchMessages : englishMessages),
  'en', // Default locale
)

export default i18nProvider
