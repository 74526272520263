import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { cyan, pink } from '@material-ui/core/colors'
import classNames from 'classnames'

import { Notification, useNotify, useLogin, useTranslate } from 'react-admin'
import config from './config'

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#00bcd4',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    textAlign: 'center',
    backgroundColor: pink,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  colors: {
    primary1Color: cyan,
    accent1Color: pink,
  },
  center: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  button: {
    width: '100%',
  },
})

// class Login extends Component {
const Login = ({ classes }) => {
  const translate = useTranslate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameHasError, setUsernameHasError] = useState(false)
  const login = useLogin()
  const notify = useNotify()
  const submit = e => {
    e.preventDefault()
    setUsernameHasError(false)

    login({ username, password }).catch(() => {
      setUsernameHasError(true)
      notify('ra.auth.sign_in_error')
    })
  }

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <form onSubmit={submit}>
          <div className={classes.form}>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <TextField name="username" error={usernameHasError} label={translate('ra.auth.username')} onChange={e => setUsername(e.target.value)} />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <TextField name="password" label={translate('ra.auth.password')} type="password" onChange={e => setPassword(e.target.value)} />
            </FormControl>
          </div>
          <CardActions className={classes.actions}>
            <Button variant="contained" type="submit" color="primary" className={classes.button} fullWidth>
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <Divider />
          <CardContent className={classes.center}>OR</CardContent>
          <Divider />
          <div className={classNames(classes.center, classes.actions)}>
            <Button
              variant="contained"
              color="secondary"
              href={`${config.backend_url}/get-in/google`}
              title={translate('hv.auth.sign_in_oauth_google')}
              className={classes.button}
            >
              {translate('hv.auth.sign_in_oauth_google')}
            </Button>
          </div>
        </form>
      </Card>
      <Notification />
    </div>
  )
}

Login.propTypes = {
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
}

export default withStyles(styles)(Login)
