export const parkList = [
  { id: '57', name: 'Acqua e Sole | Ste-Lucie-De-Porto-Vecchio' },
  { id: '137', name: 'Adriano | Punta Marina Terme' },
  { id: '114', name: 'Altomincio Family Park | Salionze di Valeggio' },
  { id: '191', name: "Amfora d'Arcs Cambrils | Tarragone" },
  { id: '168', name: 'Au Lac de Biscarrosse | Parentis-en-Born' },
  { id: '75', name: 'Baia Blu La Tortuga | Aglientu' },
  { id: '143', name: 'Bella Austria | St. Peter am Kammersberg' },
  { id: '136', name: 'Bella Italia | Peschiera Del Garda' },
  { id: '81', name: 'Bi-Village | Pula' },
  { id: '112', name: 'Bijela Uvala | Porec' },
  { id: '79', name: "Ca'Savio | Venise - Cavallino - Treporti" },
  { id: '129', name: "Cala Gogo | Platja d'Aro" },
  { id: '8', name: 'Campo di Liccia | Bonifacio' },
  { id: '67', name: 'Castell Mar | Empuriabrava' },
  { id: '68', name: 'Castell Montgri | Estartit' },
  { id: '160', name: 'Cisano San Vito | Cisano di Bardolino' },
  { id: '158', name: 'Del Garda | Peschiera Del Garda' },
  { id: '153', name: 'Dunes et Soleil | Marseillan-Plage' },
  { id: '150', name: 'Eden | San Felice del Benaco' },
  { id: '65', name: 'El Pinar | Blanes' },
  { id: '173', name: 'Erreka | Bidart' },
  { id: '166', name: 'Europa Silvella | San Felice del Benaco' },
  { id: '85', name: 'Fabulous | Rome' },
  { id: '84', name: 'Green Park | Cagnes-sur-Mer' },
  { id: '163', name: 'I Pini | Fiano Romano' },
  { id: '128', name: "Internacional de Calonge | Platja d'Aro" },
  { id: '146', name: 'Kalliste | Saint-Florent' },
  { id: '107', name: "L'Embouchure | Le Barcarès" },
  { id: '188', name: "L'Étoile d'Argens | Fréjus St-Aygulf" },
  { id: '115', name: 'La Baie des Anges | La Ciotat' },
  { id: '100', name: 'La Bastiane | Puget-sur-Argens' },
  { id: '96', name: 'La Chapelle | Argelès sur Mer' },
  { id: '181', name: 'La Clairière | La Tremblade' },
  { id: '157', name: 'La Croix du Vieux Pont | Berny-Rivière' },
  { id: '152', name: "La Marina d'Aléria | Aléria" },
  { id: '171', name: 'La Maïre | Serignan-Plage' },
  { id: '27', name: 'La Nouzarède | Joyeuse' },
  { id: '59', name: 'La Palmeraie | Torreilles-Plage' },
  { id: '23', name: 'La Pierre Verte | Fréjus' },
  { id: '91', name: 'La Pinède | La Palmyre' },
  { id: '148', name: 'La Plage et le Bord de mer | Valras-Plage' },
  { id: '55', name: "La Presqu'île | Saint-Mandrier-sur-Mer" },
  { id: '7', name: 'La Rive | Biscarrosse' },
  { id: '178', name: 'La Roseraie | La Baule-Escoublac' },
  { id: '3', name: 'La Sardane | Argelès sur Mer' },
  { id: '170', name: 'La Sirène | Argelès sur Mer' },
  { id: '183', name: 'La Vallée | Houlgate' },
  { id: '124', name: 'Lac des Vieilles Forges | Les Mazures' },
  { id: '140', name: 'Lanterna | Porec' },
  { id: '30', name: "Le Bel Air | L'Aiguillon-sur-Mer" },
  { id: '179', name: 'Le Boudigau | Labenne' },
  { id: '9', name: 'Le Chaponnet | Brem-sur-Mer' },
  { id: '154', name: 'Le Clarys Plage | St-Jean-De-Monts' },
  { id: '185', name: 'Le Clos Virgile | Serignan-Plage' },
  { id: '186', name: "Le Domaine d'Oléron | Boyardville - St-Georges-d'Oléron" },
  { id: '2', name: 'Le Domaine de Gaujac | Anduze' },
  { id: '83', name: 'Le Domaine de Ker Ys | St Nic' },
  { id: '39', name: 'Le Domaine de la Marina | Messanges' },
  { id: '174', name: 'Le Domaine des Naïades | Port Grimaud' },
  { id: '63', name: "Le Domaine du Cros d'Auzon | Vogüe" },
  { id: '33', name: 'Le Floride | Le Barcarès' },
  { id: '32', name: 'Le Jard | La-Tranche-sur-Mer' },
  { id: '34', name: 'Le Lac des 3 Vallées | Lectoure' },
  { id: '56', name: 'Le Logis | St-Palais-sur-Mer' },
  { id: '61', name: 'Le Méditerranée Plage | Vias-Plage' },
  { id: '31', name: 'Le Pansard | La-Londe-les-Maures' },
  { id: '97', name: 'Le Pré Lombard | Tarascon-sur-Ariège' },
  { id: '6', name: 'Le Ruisseau des Pyrénées | Bidart' },
  { id: '44', name: 'Le Saint Louis | Pegomas - Cannes' },
  { id: '123', name: 'Le Soleil des Landes | Lit-et-Mixe' },
  { id: '46', name: "Le Val d'Ussel | Proissans - Sarlat" },
  { id: '42', name: 'Le Val de Cesse | Mirepeisset' },
  { id: '10', name: 'Le Val de Durance | Cadenet - Lourmarin' },
  { id: '40', name: 'Le Vieux Port | Messanges' },
  { id: '177', name: 'Le Village Corsaire des 2 plages | Châtelaillon-Plage' },
  { id: '54', name: 'Les Amiaux | St-Jean-De-Monts' },
  { id: '18', name: 'Les Criques de Porteils | Argelès sur Mer' },
  { id: '95', name: 'Les Embruns | Lège Cap Ferret' },
  { id: '111', name: 'Les Grands Pins | Sanguinet' },
  { id: '190', name: 'Les Jardins Catalans | Argelès sur Mer' },
  { id: '49', name: 'Les Lacs du Verdon | Régusse - Aups' },
  { id: '176', name: 'Les Menhirs | Carnac' },
  { id: '135', name: 'Les Méditerranées - Camping Beach Garden | Marseillan-Plage' },
  { id: '36', name: 'Les Méditerranées - Camping Charlemagne | Marseillan-Plage' },
  { id: '37', name: 'Les Méditerranées - Camping Nouvelle Floride | Marseillan-Plage' },
  { id: '189', name: 'Les Pins | Argelès sur Mer' },
  { id: '45', name: 'Les Prairies de la Mer | Port Grimaud' },
  { id: '16', name: 'Les Rives du Luberon | Cheval-Blanc' },
  { id: '60', name: 'Les Sablines | Vendres-Plage' },
  { id: '17', name: 'Les Viviers | Lège Cap Ferret' },
  { id: '89', name: 'Lou Cigalon | La Couronne - Sausset Les Pins' },
  { id: '41', name: 'Lou Pignada | Messanges' },
  { id: '167', name: 'Marina Paradise | Cogolin (Port) - St Tropez' },
  { id: '24', name: "Marina d'Erba Rossa | Ghisonaccia" },
  { id: '161', name: 'Marina di Venezia | Venise - Cavallino - Treporti' },
  { id: '138', name: 'Marina | Punta Marina Terme' },
  { id: '13', name: "Mer et Soleil | Cap D'Agde" },
  { id: '72', name: 'Neptuno | Pals' },
  { id: '90', name: 'Norcenni Girasole Club | Florence- Figline Valdarno' },
  { id: '187', name: 'Parco Delle Piscine | Sarteano' },
  { id: '132', name: 'Paris Est | Paris-Champigny' },
  { id: '78', name: 'Park Albatros | San Vincenzo' },
  { id: '164', name: 'Park Umag | Umag' },
  { id: '182', name: 'Playa Brava | Pals' },
  { id: '175', name: 'Playa Montroig | Miami Platja' },
  { id: '102', name: 'Port Pothuau | Hyères les Palmiers' },
  { id: '180', name: 'Portofelice | Eraclea Mare' },
  { id: '87', name: "Pra' Delle Torri | Caorle" },
  { id: '156', name: 'Sanguli | Salou' },
  { id: '66', name: 'Sant Miquel | Sant Miquel de Colera' },
  { id: '162', name: "Sant'Angelo Village | Venise - Cavallino - Treporti" },
  { id: '110', name: "Signol | Boyardville - St-Georges-d'Oléron" },
  { id: '80', name: 'Simuni | Pag' },
  { id: '155', name: 'Sol à Gogo | St-Hilaire-De-Riez' },
  { id: '133', name: 'Sole Di Sari | Solenzara' },
  { id: '113', name: 'Soline | Biograd' },
  { id: '73', name: 'Tamarit Park | Tarragone' },
  { id: '149', name: "Tonnara | Sant'Antioco" },
  { id: '139', name: 'Union Lido | Venise - Cavallino - Treporti' },
  { id: '141', name: 'Val de Bonnal | Bonnal' },
  { id: '77', name: 'Vigna Sul Mar | Lido Di Pomposa' },
  { id: '93', name: 'Vilanova Park | Vilanova I La Geltru' },
  { id: '88', name: 'Village Spina | Lido Di Spina - Comacchio' },
  { id: '86', name: 'Villaggio San Francesco | Caorle' },
  { id: '82', name: 'Zaton Holiday Resort | Zadar' },
]

export const MHTypes = [
  { id: '940', name: 'Bungalow' },
  { id: '941', name: 'Cottage' },
  { id: '942', name: 'Villa' },
  { id: '939', name: 'Autre hébergement' },
]

export const capacities = [
  { id: '2', name: '2 personnes' },
  { id: '3', name: '3 personnes' },
  { id: '4', name: '4 personnes' },
  { id: '5', name: '5 personnes' },
  { id: '6', name: '6 personnes' },
  { id: '7', name: '+ de 6 personnes' },
]

export const preResaUsers = [
  { id: 'aurelie', name: 'Aurélie' },
  { id: 'céline', name: 'Céline' },
  { id: 'coralie', name: 'Coralie' },
  { id: 'désirée', name: 'Désirée' },
  { id: 'elisa', name: 'Elisa' },
  { id: 'françoise', name: 'Françoise' },
  { id: 'kamariya', name: 'Kamariya' },
  { id: 'kathleen', name: 'Kathleen' },
  { id: 'liliane', name: 'Liliane' },
  { id: 'maeva', name: 'Maéva' },
  { id: 'marion', name: 'Marion' },
  { id: 'michelle', name: 'Michelle' },
  { id: 'nassima', name: 'Nassima' },
  { id: 'olivier', name: 'Olivier' },
  { id: 'ricardo', name: 'Ricardo' },
  { id: 'sakina', name: 'Sakina' },
  { id: 'tanguy', name: 'Tanguy' },
]

export const civilityList = [
  { id: 'mme', name: 'Madame' },
  { id: 'mr', name: 'Monsieur' },
]

export const requestStates = [
  { id: 'a-rapp', name: 'A recontacter par tel/mail' },
  { id: 'non-preresa', name: 'Client a refusé la réservation' },
  { id: 'resa-ok', name: 'Client OK pour la réservation' },
  { id: 'stb', name: 'En attente retour client' },
  { id: 'msg-rep', name: 'Message répondeur / Mail envoyé' },
  { id: 'sur-internet', name: 'Réservera sur Internet' },
  { id: 'injoignable', name: 'Client injoignable (contacté 3 fois) = Clôturé' },
]

export const reasonList = [
  { id: '0', name: '- Aucun -' },
  { id: 'deja-reserve-cc', name: 'Client a déjà réservé auprès de la concurrence' },
  { id: 'pas-interesse', name: 'Client pas/plus intéressé' },
  { id: 'complet', name: 'Complet' },
  { id: 'tuple', name: 'Doublon/Triplon' },
  { id: 'pas-conges', name: 'Pas de congés' },
  { id: 'plus-tard', name: 'Réservera plus tard' },
  { id: 'catalog', name: 'Souhaitait le catalogue' },
  { id: 'mh-precis', name: 'Souhaitait le choix du MH précis' },
  { id: 'autre-mh', name: "Souhaitait un autre type d'hébergement" },
  { id: 'trop-cher', name: 'Trop cher' },
]

export const requestProcessed = [
  { id: 'non_traitee', name: 'Non traitée' },
  { id: 'en_cours', name: 'En cours' },
  { id: 'traitee', name: 'Traitée' },
]

export const provenanceList = [
  { id: '-', name: '- Aucun -' },
  { id: 'email', name: 'Email' },
  { id: 'fax', name: 'Fax' },
  { id: 'papier', name: 'Papier' },
  { id: 'web', name: 'Web' },
]

export const countries = [
  { id: 'FRA', name: 'France' },
  { id: 'DEU', name: 'Allemagne' },
  { id: 'HRV', name: 'Croatie' },
  { id: 'DNK', name: 'Danemark' },
  { id: 'ESP', name: 'Espagne' },
  { id: 'ITA', name: 'Italie' },
  { id: 'GBR', name: 'Royaume-Uni' },
  { id: 'AUT', name: 'Autriche' },
  { id: 'BEL', name: 'Belgique' },
  { id: 'CAN', name: 'Canada' },
  { id: 'CHN', name: 'Chine' },
  { id: 'CHN-en', name: 'Chine' },
  { id: 'USA', name: 'États-Unis' },
  { id: 'FIN', name: 'Finlande' },
  { id: 'HKG-en', name: 'Hong Kong' },
  { id: 'HUN', name: 'Hongrie' },
  { id: 'ISR', name: 'Israël' },
  { id: 'JPN', name: 'Japon' },
  { id: 'JPN-en', name: 'Japon' },
  { id: 'LUX', name: 'Luxembourg' },
  { id: 'MAR', name: 'Maroc' },
  { id: 'NOR', name: 'Norvège' },
  { id: 'NZL', name: 'Nouvelle-Zélande' },
  { id: 'NLD', name: 'Pays-Bas' },
  { id: 'POL', name: 'Pologne' },
  { id: 'PRT', name: 'Portugal' },
  { id: 'QAT', name: 'Qatar' },
  { id: 'CZE', name: 'République tchèque' },
  { id: 'ROU', name: 'Roumanie' },
  { id: 'RUS', name: 'Russie' },
  { id: 'SGP', name: 'Slovénie' },
  { id: 'SVN', name: 'Slovénie' },
  { id: 'SWE', name: 'Suède' },
  { id: 'CHE', name: 'Suisse' },
  { id: 'THA', name: 'Thaïlande' },
  { id: 'TUR', name: 'Turquie' },
]
