// in ./ResetViewsButton.js
import React, { useState, Fragment } from 'react'
import { Button, Confirm, useNotify, useTranslate, useRefresh, useUnselectAll, useDeleteMany } from 'react-admin'
import DeleteIcon from '@material-ui/icons/Delete'

//
// https://marmelab.com/react-admin/List.html
//
const BulkDeleteConfirmButton = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const translate = useTranslate()

  // https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/dataProvider/useDeleteMany.ts
  const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
    onSuccess: () => {
      refresh()
      notify(translate('ra.notification.deleted', { smart_count: selectedIds.length }))
      unselectAll(resource)
    },
    onFailure: error => notify('Error: items not removed', 'warning'),
    // EVO: NE FONCTIONNE PAS avev TRUE (31/01/2020)
    undoable: false,
  })
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    deleteMany()
    setOpen(false)
  }

  return (
    <Fragment>
      <Button label="Remove selected" disabled={loading} onClick={handleClick}>
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={open}
        title="Remove these items"
        content={translate('ra.message.bulk_delete_content', { name: 'preresas', smart_count: selectedIds.length })}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

// class BulkDeleteConfirmButton2 extends Component {
//   state = {
//     isOpen: false,
//   }

//   handleClick = () => {
//     this.setState({ isOpen: true })
//   }

//   handleDialogClose = () => {
//     this.setState({ isOpen: false })
//   }

//   handleConfirm = () => {
//     const { basePath, dispatchCrudDeleteMany, resource, selectedIds, startUndoable, undoable } = this.props
//     if (undoable) {
//       startUndoable(crudDeleteMany(resource, selectedIds, basePath))
//     } else {
//       dispatchCrudDeleteMany(resource, selectedIds, basePath)
//     }
//   }

//   render() {
//     return (
//       <Fragment>
//         {/*<Button label="Reset Views" onClick={this.handleClick} />*/}
//         <BulkDeleteButton {...this.props} onClick={this.handleClick} />
//         <Confirm
//           isOpen={this.state.isOpen}
//           title="Update View Count"
//           content="Are you sure you want to reset the views for these items?"
//           onConfirm={this.handleConfirm}
//           onClose={this.handleDialogClose}
//         />
//       </Fragment>
//     )
//   }
// }

// BulkDeleteConfirmButton = compose(
//   connect(undefined, {
//     startUndoable,
//     dispatchCrudDeleteMany: crudDeleteMany,
//   }),
// )(BulkDeleteConfirmButton)

export default BulkDeleteConfirmButton
