import React, { Component } from 'react'
import moment from 'moment'
// import DateTimeInput from 'aor-datetime-input'
import { get, find } from 'lodash'
import {
  Show,
  ShowButton,
  ListButton,
  SimpleShowLayout,
  Labeled,
  List,
  TextField,
  TextInput,
  Filter,
  Datagrid,
  SelectInput,
  Responsive,
  SimpleList,
  useTranslate,
  Pagination,
} from 'react-admin'
import { marked } from 'marked'
import { CardActions, Card } from '@material-ui/core'
import AckedIcon from '@material-ui/icons/AssignmentInd'
import AlertIcon from '@material-ui/icons/Alarm'
import ResolvedIcon from '@material-ui/icons/Done'
import JsonViewerButton from './JsonViewerButton'
import ChangeStatusButton from './ChangeStatusButton'

//
// --------------- FORMATEURS POUR LES FORMULAIRES --------------------
// formateurs pour les listes
const DateHourField = (props) => (
  <span>{get(props.record, props.source) ? moment.utc(get(props.record, props.source)).local().format('YYYY-MM-DD HH:mm:ss') : ''}</span>
)
DateHourField.defaultProps = { addLabel: true }

const ValueListField = (props) => {
  const mySource = get(props.record, props.source)
  const oLabel = find(props.valueList, { id: mySource })
  if (mySource && oLabel && oLabel.name) {
    if (props.labeled) {
      return (
        <Labeled label={props.label}>
          <span>{oLabel.name}</span>
        </Labeled>
      )
    } else {
      return <span>{oLabel.name}</span>
    }
  } else return <span>{}</span>
}

const statusesChoices = [
  { id: 1, name: 'Firing' },
  { id: 9, name: 'Resolved' },
]

const AlertFilter = (props) => {
  const translate = useTranslate()

  return (
    <Filter {...props}>
      <TextInput label={translate('hv.alerts.filters.search')} source="q" alwaysOn />
      <SelectInput source="status" label={translate('hv.alerts.filters.status')} choices={statusesChoices} />
    </Filter>
  )
}

const postRowStyle = (record) => ({
  backgroundColor: record.status === 9 ? '#c3dec3' : record.ackAt ? '#fc6' : '#ff9b9b',
  color: record.status === 9 ? '#666' : '#000',
})

const iconByStatus = (record) => {
  if (record.ackAt && record.status !== 9) {
    return <AckedIcon style={{ backgroundColor: '#fc6' }} />
  } else if (record.status === 9) {
    return <ResolvedIcon style={{ backgroundColor: '#c3dec3' }} />
  } else {
    return <AlertIcon style={{ backgroundColor: '#ff9b9b' }} />
  }
}

export class AlertList extends Component {
  componentDidMount() {
    document.title = 'Homair alerts'
  }

  resolveAlert(a) {
    console.log(a)
  }

  render() {
    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} {...props} />

    return (
      <div>
        <List {...this.props} filters={<AlertFilter />} sort={{ field: 'endAt', order: 'DESC' }} title="Alerts list" pagination={<ListPagination />}>
          <Responsive
            small={
              <SimpleList
                primaryText={(record) => record.key}
                secondaryText={(record) => `${record.group}`}
                tertiaryText={(record) => <DateHourField source="endAt" record={record} />}
                leftAvatar={iconByStatus}
                linkType="show"
              />
            }
            medium={
              <Datagrid rowStyle={postRowStyle} /*bodyOptions={{ stripedRows: true, showRowHover: true }}*/>
                <TextField source="key" label="Key" />
                <ShowMetadata metaName="title" />
                <ShowButton />
                <DateHourField source="startAt" label="start at" />
                <DateHourField source="updatedAt" label="updated at" />
                <DateHourField source="endAt" label="end at" />
                <TextField source="duration" label="Duration" />
                <TextField source="count" label="Occurs..." />
                <TextField source="ackBy.username" label="Ack by" />
                <ValueListField source="status" valueList={statusesChoices} label="Status" labeled={false} />

                <JsonViewerButton />
                <ChangeStatusButton action="ack" redirectAfter />
                <ChangeStatusButton action="resolve" redirectAfter />
                <TextField source="group" label="Group" />
                <TextField source="id" label="Id" />
              </Datagrid>
            }
          />
        </List>
      </div>
    )
  }
}

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
}

const AlertTitle = () => <span>Alert details</span>
const AlertActions = ({ basePath, className, data, hasEdit, resource, ...rest }) => {
  return (
    <CardActions style={cardActionStyle}>
      <JsonViewerButton record={data} />
      <ChangeStatusButton action="ack" record={data} redirectAfter={false} />
      <ChangeStatusButton action="resolve" record={data} redirectAfter={false} />

      {<ListButton basePath={basePath} record={data} />}
    </CardActions>
  )
}

const ShowMetadata = (props) => {
  const { record } = props
  let ret = []

  const metaNames = props.metaName.split(',')
  metaNames.forEach((metaName, i) => {
    metaName = metaName.trim()
    let metaValue = get(record, `metadatas.${metaName}`, false)

    // if (metaValue.indexOf('sharpeye.homair') !== -1 || metaValue.indexOf('http') !== -1) {
    //   if (metaValue.indexOf('http') === -1) {
    //     metaValue = `http://${metaValue}`
    //   }

    //   ret.push(
    //     <li key={'meta-' + i}>
    //       <a target="_blank" href={metaValue}>
    //         {metaValue}
    //       </a>
    //     </li>,
    //   )
    // }

    // Try to surround the title with a link.
    if (metaName === 'title') {
      let link = get(record, `metadatas.titleLink`, '')

      if (link) {
        if (link.indexOf('http') === -1) {
          link = `http://${link}`
        }

        ret.push(
          <li key={`meta-${i}`}>
            <a target="_blank" href={link} rel="noopener noreferrer" >
              {metaValue}
            </a>
          </li>,
        )
      }
    }

    // Fields details.
    else if (metaName === 'fields' && Array.isArray(metaValue)) {
      metaValue.forEach((f, j) => {
        ret.push(
          <li key={`meta-${i}-${j}`}>
            <a target="_blank" href={`http://${f.value}`} rel="noopener noreferrer">
              {f.title}
            </a>
          </li>,
        )
      })
    } else if (metaValue) {
      ret.push(<li style={{maxWidth: '100vw'}} key={`meta-${i}`} dangerouslySetInnerHTML={{ __html: marked(metaValue) }} />)
    }
  })

  if (ret.length > 0) {
    return <Card className="metadatas">{ret}</Card>
  }
  return null
}

class FormDataShow extends Component {
  render() {
    const { record } = this.props
    const data = JSON.stringify(record.metadatas, null, 2).replace(new RegExp('<', 'g'), '&lt;').replace(new RegExp('>', 'g'), '&gt;')

    const debug = <textarea style={{ height: '300px', width: '100%',fontFamily: "Courier", backgroundColor:'#333',color:'#eee' }}>{data}</textarea>

    return debug
  }
}

// Formulaire de détail
export class AlertShow extends Component {
  render() {
    return (
      <Show title={<AlertTitle />} {...this.props} actions={<AlertActions />}>
        <SimpleShowLayout>
          <ShowMetadata metaName="title, message, description, fields" />

          <ValueListField source="status" valueList={statusesChoices} label="Status" />
          <TextField source="key" label="Key" />
          <TextField source="group" label="Group" />

          <DateHourField source="startAt" label="Start at" />
          <DateHourField source="updatedAt" label="updated at" />
          <DateHourField source="endAt" label="end at" />
          <TextField source="duration" label="Duration" />
          <FormDataShow sourceKey="metadatas" {...this.props} />
        </SimpleShowLayout>
      </Show>
    )
  }
}
