import { BarChart, Bar, Tooltip, XAxis, YAxis, Legend } from 'recharts'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class CustomizedLabel extends Component {
  render() {
    const { x, y, stroke, value } = this.props
    if (!isNaN(x) && !isNaN(y)) {
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="right">
          {value}
        </text>
      )
    } else {
      return <text />
    }
  }
}

class PreResaChart extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <BarChart width={600} height={200} data={this.props.data}>
          <XAxis dataKey="hv" />
          <YAxis />
          <Legend verticalAlign="top" height={36} />
          <Bar type="monotone" dataKey="count" fill="#8884d8" name="N" label={<CustomizedLabel />} />
          <Bar type="monotone" dataKey="hv_n1" fill="#f6c" name="N-1" label={<CustomizedLabel />} />
          <Tooltip />
        </BarChart>
      </div>
    )
  }
}

PreResaChart.propTypes = {
  data: PropTypes.array,
}

export default PreResaChart
