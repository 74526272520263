import englishMessages from 'ra-language-english'

export const messages = {
  ...englishMessages,

  hv: {
    auth: {
      sign_in_oauth_google: 'Sign-in with Google',
    },
    action: {
      save_and_edit: 'Save & edit',
      save: 'Save',
    },
    alerts: {
      ack: 'Alert acknowledged successfully !',
      ack_error: 'Alert not acknowledged due to error.',
      unack: 'Alert unacknowledged successfully !',
      unack_error: 'Alert not unacknowledged due to error.',
      resolve: 'Alert resolved',
      resolve_error: 'Alert not resolved due to error.',
      reopen: 'Alert re-opened',
      reopen_error: 'Alert not re-opened due to error.',

      filters: {
        search: 'Search',
        status: 'Status',
      },
    },
    user: {
      list: {
        title: 'Users manager',
      },
    },
  },
}

export default messages
