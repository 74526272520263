import React from 'react'
import moment from 'moment'
// import DateTimeInput from 'aor-datetime-input'
import { get } from 'lodash'
import {
  Datagrid,
  DatagridBody,
  DateInput,
  DeleteButton,
  ListButton,
  Filter,
  List,
  Pagination,
  Responsive,
  Show,
  ShowButton,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  BooleanInput,
} from 'react-admin'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

// --------------------------------------------------------------------
// ----------- FORMATEURS POUR LES listes et formulaires---------------
// --------------------------------------------------------------------
const DateOnlyField = (date) => (
  <span>{date ? moment.utc(date).local().format('YYYY-MM-DD') : ''}</span>
)
const DateHourField = (props) => (
  <span>{get(props.record, props.source) ? moment.utc(get(props.record, props.source)).local().format('YYYY-MM-DD HH:mm:ss') : ''}</span>
)
DateHourField.defaultProps = { addLabel: true }

const LimitedTextField = ({ source, record, maxLength }) => {
  if (get(record, source) && maxLength < get(record, source).length) {
    return <span>{`${get(record, source).substr(0, maxLength)}...`}</span>
  }
  return <span>{get(record, source)}</span>
}

// --------------------------------------------------------------------
// Custom datagrid to disable checkboxes for already deleted records
// --------------------------------------------------------------------
const ConsentDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
  <TableRow key={id}>
    {/* first column: selection checkbox */}
    <TableCell padding="none">
      {(!record.deleted_at || record.deleted_at === null) && <Checkbox checked={selected} onClick={() => onToggleItem(id)} />}
    </TableCell>
    {/* data columns based on children */}
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
)

const ConsentDatagridBody = (props) => <DatagridBody {...props} row={<ConsentDatagridRow />} />
const ConsentDatagrid = (props) => <Datagrid {...props} body={<ConsentDatagridBody />} />

// --------------------------------------------------------------------

const ConsentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <DateInput source="created_at_before" label="Date de consentement avant le (≤) :" />
    <DateInput source="created_at_after" label="Date de consentement après le (≥) :" />
    <BooleanInput label="Deleted" source="deleted" />
  </Filter>
)
export const ConsentList = (props) => {
  const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} {...props} />

  return (
    <div>
      <List
        {...props}
        filters={<ConsentFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        title="Consentements recueillis sur les formulaires du site Homair"
        pagination={<ListPagination />}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={(record) => record.identification}
              secondaryText={(record) => record.origin}
              tertiaryText={(record) => <DateOnlyField source="created_at" />}
            />
          }
          medium={
            <ConsentDatagrid /*bodyOptions={{ stripedRows: true, showRowHover: true }}*/>
              <AnonymizedTextField label="Nom" />
              <AnonymizedTextField label="Prénom" />
              <TextField source="identification" label="Email / ID client" />
              <TextField source="origin" label="Formulaire" />
              <LimitedTextField source="consent" label="Consentement" maxLength="50" />
              <DateHourField source="created_at" label="Ajoutée le" />
              <DateHourField source="deleted_at" label="Supprimé le" />
              <ShowButton />
            </ConsentDatagrid>
          }
        />
      </List>
    </div>
  )
}

const AnonymizedTextField = ({ source, record = {}, label }) => <span>{label === 'Nom' ? 'XXXXX' : 'xxxxx'}</span>

const ConsentTitle = ({ record }) => <span>Vue détaillée du consentement de {record && record.identification ? `: ${record.identification}` : ''}</span>

const ShowActions = ({ basePath, data, resource }) => {
  let deleteButton
  if (!data || (data && data.deleted_at === null)) {
    deleteButton = <DeleteButton basePath={basePath} record={data} resource={resource} />
  }
  return (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
      {deleteButton}
    </TopToolbar>
  )
}

// Formulaire de détail
export const ConsentShow = (props) => (
  <Show title={<ConsentTitle />} actions={<ShowActions />} {...props}>
    <SimpleShowLayout>
      {/*<TextField source="lastname" label="Nom" />
      <TextField source="firstname" label="Prénom" />*/}
      <TextField source="email" label="Email" />
      <TextField source="clientId" label="ID Client" />
      <TextField source="origin" label="Formulaire d'origine" />
      <TextField source="brand_id" label="Marque" />
      <TextField source="consent" label="Consentement" />
      <DateHourField source="created_at" label="Ajoutée le" />
      <DateHourField source="deleted_at" label="Supprimé le" />
      {/*<FormDataShow sourceKey="formData" {...props} /> */}
      <TextField source="request_id" label="Identifiant technique de requête" />
    </SimpleShowLayout>
  </Show>
)

// const FormDataShow = (props) => {
//   const formFields = []
//   if (props.record[props.sourceKey]) {
//     Object.entries(props.record[props.sourceKey]).forEach((el, i) => {
//       formFields.push(
//         <div key={`form-${i}`}>
//           <span className="consent-form-key">{`${[el[0]]} : `}</span>
//           <span className="consent-form-value">{[el[1]]}</span>
//         </div>,
//       )
//     })
//   }
//   return (
//     <div>
//       <div className="consent-form-title">{'Contenu du formulaire :'}</div>
//       <div className="consent-form">{formFields}</div>
//     </div>
//   )
// }
