import MomentUtils from '@date-io/moment'
import { Field } from 'react-final-form'

import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'

const MuiDatePicker = ({ id, label, input, ...rest }) => {
  const handleDateChange = date => {
    input.onChange(date ? date.format() : null)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/YYYY"
        margin="normal"
        id={id}
        label={label}
        value={input.value ? input.value : null}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoOk
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

const MuiDateTimePicker = ({ id, label, input, ...rest }) => {
  const handleDateChange = date => {
    input.onChange(date ? date.format() : null)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        disableToolbar
        variant="inline"
        format="DD/MM/YYYY HH:mm:ss"
        margin="normal"
        id={id}
        label={label}
        value={input.value ? input.value : null}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoOk
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

export const CustomDateTimePicker = props => (
  <Field name={props.source} component={MuiDateTimePicker} label={props.label}>
    {props => <MuiDateTimePicker {...props} />}
  </Field>
)
export const CustomDatePicker = props => (
  <Field name={props.source} component={MuiDatePicker} label={props.label}>
    {props => <MuiDatePicker {...props} />}
  </Field>
)
