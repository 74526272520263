import lodashGet from 'lodash/get'
import lodashFind from 'lodash/find'
import lodashKeyBy from 'lodash/keyBy'
import { useDispatch } from 'react-redux'
import { changeListParams } from 'react-admin'
import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import grey from '@material-ui/core/colors/grey'
import { restClient } from '../../App'
import { reasonList, requestStates } from '../constants'
import PreResaChart from './preResaChart'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 400,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: grey[100],
    color: grey[900],
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const MiniStatCard = ({ stats, dispStatKey, filterStatKey, filterValue, title, value, clickHandler, classes }) => (
  <Paper className="paperStyle" elevation={lodashGet(stats, dispStatKey, 2)} onClick={clickHandler(filterStatKey, filterValue)}>
    <div className="pre_resa_title">{title}</div>
    <div className="pre_resa_score">
      <Avatar className={classes.avatar}>{value}</Avatar>
    </div>
  </Paper>
)

const PreResaStatsCard = ({ resource, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState(false)
  const [paperShadow, setPaperShadow] = useState({
    rapp: 2,
    msg: 2,
    refus: 2,
    ok: 2,
    internet: 2,
    non_traitee: 2,
    en_cours: 2,
    traitee: 2,
    etatDemande: 2,
    total: 2,
  })
  const [stats, setStats] = useState({
    scores: {
      state: {},
      processed: {},
      reasons: {},
    },
    countTotalN1: 0,
    countN: 0,
    countN1: 0,
    chartData: [],
    currentData: [],
    previousYearData: [],
  })
  // if (!stats.scores) stats.scores = {}

  console.log('DEFAULT:', props)

  // const handleStatusChange = status => setIsOnline(status.isOnline)

  //
  // Avoid infinite loop !!
  // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
  // https://medium.com/@andrewmyint/infinite-loop-inside-useeffect-react-hooks-6748de62871
  // 2nd argument: [], pour dire à react de n'appeler cet effect qu'une seule fois.
  // car il ne dépend pas d'un state.
  //
  useEffect(() => {
    restClient
      .getList('preresa-stats', { pagination: {}, sort: {} })
      .then(results => {
        if (results) {
          setStats(results.data)
        }
      })
      .catch(err => {
        console.error('callRestStats', err)
      })
  }, [])

  // this.listRef = props.listRef

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const clickHandler = (fieldName, value) => e => {
    e.stopPropagation()
    dispatch(changeListParams(resource, { filter: { [fieldName]: value } }))
  }

  // design
  const onMouseOver = (e, shadow) => {
    paperShadow[shadow] = 3
    setPaperShadow(paperShadow)
  }

  // stats 'en cours'
  let enCours = []
  Object.keys(stats.scores.state).forEach(key => {
    let label = lodashFind(requestStates, { id: key })
    label = label ? label.name : key
    if (key === 'null') label = 'Non renseigné'
    const oneScore = lodashGet(stats, `scores.state[${key}]`)
    oneScore &&
      enCours.push(
        <li key={`ec-${key}`} onClick={clickHandler('request_state', key)}>
          <span className="label">{label} </span>
          <span className="score">{oneScore}</span>
        </li>,
      )
  })
  // stats.scores.state['stb'] && enCours.push(<li key='ec-2'>En attente : <span className="score">{stats.scores.state['stb']}</span></li>)
  // stats.scores.state['a-rapp'] && enCours.push(<li key='ec-3'>A rappeler : <span className="score">{stats.scores.state['a-rapp']}</span></li>)
  // stats.scores.state['va-reflechir'] && enCours.push(<li key='ec-4'>Va réfléchir : <span className="score">{stats.scores.state['va-reflechir']}</span></li>)

  // liste des refus
  let refus = []
  const aReasonList = lodashKeyBy(reasonList, 'id')
  Object.keys(aReasonList).forEach(key => {
    const oneReason = lodashGet(stats, `scores.reasons[${key}]`)
    if (oneReason) {
      refus.push(
        <li key={`refus-${key}`} onClick={clickHandler('reason', key)}>
          <span className="label">{`${aReasonList[key].name} : `}</span>
          <span className="score">{oneReason}</span>
        </li>,
      )
    }
  })

  return (
    <Card className="clearfix">
      <CardHeader
        title="Statistiques"
        className="cardHeader"
        action={
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      ></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className="cardContent clearfix">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MiniStatCard
                stats={stats}
                dispStatKey="paperShadow.total"
                filterStatKey="request_processed"
                filterValue="total"
                title="Total"
                clickHandler={clickHandler}
                onMouseOver={e => onMouseOver(e, 'total')}
                value={
                  lodashGet(stats, 'scores.processed.non_traitee', 0) +
                  lodashGet(stats, 'scores.processed.en_cours', 0) +
                  lodashGet(stats, 'scores.processed.traitee', 0)
                }
                classes={classes}
              />
              <MiniStatCard
                stats={stats}
                dispStatKey="paperShadow.non_traitee"
                filterStatKey="request_processed"
                filterValue="non_traitee"
                title="Non traitées"
                clickHandler={clickHandler}
                onMouseOver={e => onMouseOver(e, 'non_traitee')}
                value={lodashGet(stats, 'scores.processed.non_traitee', 0)}
                classes={classes}
              />
              <MiniStatCard
                stats={stats}
                dispStatKey="paperShadow.en_cours"
                filterStatKey="request_processed"
                filterValue="en_cours"
                title="En cours"
                clickHandler={clickHandler}
                onMouseOver={e => onMouseOver(e, 'en_cours')}
                value={lodashGet(stats, 'scores.processed.enCours', 0)}
                classes={classes}
              />
              <MiniStatCard
                stats={stats}
                dispStatKey="paperShadow.traitee"
                filterStatKey="request_processed"
                filterValue="traitee"
                title="Traitées"
                clickHandler={clickHandler}
                onMouseOver={e => onMouseOver(e, 'traitee')}
                value={lodashGet(stats, 'scores.processed.traitee', 0)}
                classes={classes}
              />
            </Grid>
            <Grid item xs={3}>
              <Paper
                className="paperStyle paperList"
                elevation={lodashGet(stats, 'paperShadow.etatDemande', 2)}
                onClick={clickHandler('request_processed', 'en_cours')}
                onMouseOver={e => onMouseOver(e, 'enCours')}
              >
                <div className="pre_resa_title">Etat demande</div>
                <ul className="pre_resa_ul">{enCours}</ul>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className="paperStyle paperList" elevation={2}>
                <div className="pre_resa_title">Liste des refus</div>
                <div>
                  <ul style={{ textAlign: 'left' }}>{refus}</ul>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <div className="chartContainer clearfix">
                <div className="sectionTitle">Comparaison années N vs N-1</div>
                <div style={{ width: '100%', height: '220px' }}>
                  {stats.chartData ? <PreResaChart data={lodashGet(stats, 'chartData', [])} /> : ''}
                  Cette année : <strong>{stats.countN || 0}</strong>, YTD N-1 : <strong>{stats.countN1 || 0}</strong>, total N-1 :{' '}
                  <strong>{stats.countTotalN1 || 0}</strong>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}

// const mapStateToProps = state => ({ rate: state.bitcoinRate });
// const mapStateToProps = () => ()

export default PreResaStatsCard
