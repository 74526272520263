import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showNotification, refreshView } from 'react-admin'
import config from '../../config'
import { httpClient } from '../../App'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

class ChangeStatusButton extends Component {
  handleClick = () => {
    const { record, showNotification, action, redirectAfter, refreshView } = this.props
    let realAction
    if (action === 'resolve') {
      realAction = record.status === 9 ? 'reopen' : 'resolve'
    } else if (action === 'ack') {
      realAction = record.ackBy ? 'unack' : 'ack'
    }

    httpClient(`${config.backend_url}/api/alert/${record.id}/${realAction}`, { method: 'PUT' })
      .then(() => {
        showNotification(`hv.alerts.${realAction}`)

        if (redirectAfter) {
          // push('/')
          // push('/api/alert')
          refreshView()
          // return [<Redirect to="/" />, <Redirect to="/api/alert/" />]
        } else {
          // Un peu violent, pour rafraichir la page...
          window.location.reload()
        }
      })
      .catch(e => {
        console.error(e)
        showNotification(`hv.alerts.${realAction}_error`, 'warning')
      })
  }

  render() {
    const { record, status, action } = this.props

    // If button goal is to set the same status => don't display the button.
    if (!record || (record && record.status === status)) {
      return null
    }

    switch (action) {
      case 'resolve':
        if (record.status !== 9) {
          return (
            <Tooltip title="Resolve this alert">
              <IconButton onClick={this.handleClick}>
                <CheckCircleIcon style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title="Re-open this alert">
              <IconButton onClick={this.handleClick}>
                <AlarmOnIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          )
        }

      case 'ack':
        if (record && !record.ackAt) {
          return (
            <Tooltip title="Ack this alert">
              <IconButton onClick={this.handleClick}>
                <AssignmentIndIcon />
              </IconButton>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title="Unack this alert">
              <IconButton onClick={this.handleClick}>
                <AssignmentLateIcon />
              </IconButton>
            </Tooltip>
          )
        }

      default:
        console.warn('ChangeStatusButton: action attribute is mandatory.')
        return null
    }
  }
}

ChangeStatusButton.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export default connect(null, {
  showNotification,
  refreshView,
})(ChangeStatusButton)
