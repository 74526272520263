import React from 'react'
import moment from 'moment'
// import DateTimeInput from 'aor-datetime-input'
import { get } from 'lodash'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { CustomDatePicker, CustomDateTimePicker } from '../../components/CustomDatePickers'
import { DateHourFieldDisplay } from '../../components/DateHourFields'
import {
  AutocompleteInput,
  BooleanInput,
  choices,
  Create,
  Edit,
  RadioButtonGroupInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin'
import {
  capacities,
  civilityList,
  countries,
  MHTypes,
  parkList,
  preResaUsers,
  provenanceList,
  reasonList,
  requestProcessed,
  requestStates,
} from '../constants'

const DateHourField = props => (
  <span>
    {get(props.record, props.source)
      ? moment
          .utc(get(props.record, props.source))
          .local()
          .format('YYYY-MM-DD HH:mm:ss')
      : ''}
  </span>
)
DateHourField.defaultProps = { addLabel: true }

// validators
const requiredPhone = (value, allValues) =>
  allValues.mobile_phone || allValues.phone ? undefined : 'Vous devez renseigner au moins un numéro de téléphone'
const required = (message = 'Ce champ est obligatoire') => value => (value ? undefined : message)
const email = function email(message = 'Ce champ est obligatoire') {
  return function(value) {
    if (!value) return message
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
    return re.test(value) ? undefined : 'Merci de saisir un email valide'
  }
}

const PreResaTitle = ({ record }) => (
  <span>
    Création/édition d'une demande de réservation{' '}
    {record && record.lastname
      ? `: ${record.civility[0].toUpperCase() + record.civility.substr(1)} ${record.firstname} ${record.lastname.toUpperCase()}`
      : ''}
  </span>
)

const MyForm = () => (
  <Grid container spacing={3}>
    <Grid item lg={12}>
      <TextInput source="resa_comments" label="Note interne" multiline={true} fullWidth={true} />
    </Grid>
    <Grid item container spacing={3}>
      <Grid item lg={4}>
        <Grid item container direction="row" wrap="wrap" spacing={1}>
          <Grid item lg={6}>
            <TextInput source="customer_number" label="N° client" fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <SelectInput
              source="civility"
              label="Civilité"
              validation={choices(civilityList.map(el => el.id))}
              choices={civilityList}
              fullWidth={true}
            />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="lastname" validate={[required()]} label="Nom" fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="firstname" validate={[required()]} label="Prénom" fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="birthday" label="Date de naissance" fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="phone" validate={requiredPhone} label="Téléphone" fullWidth={true} />
          </Grid>
          <Grid item lg={12}>
            <TextInput source="address" label="Adresse" multiline={true} fullWidth={true} />
          </Grid>
          <Grid item lg={4}>
            <TextInput source="zip_code" label="Code postal" fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="city" label="Ville" fullWidth={true} />
          </Grid>
          <Grid item lg={12}>
            <SelectInput source="country" label="Pays" choices={countries} fullWidth={true} />
          </Grid>
          <Grid item lg={12}>
            <TextInput source="email" validate={[required(), email()]} fullWidth={true} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={4}>
        <Grid item container direction="row" wrap="wrap" spacing={1}>
          <Grid item lg={6}>
            <CustomDatePicker source="start_date" label="Début de séjour" disablePast />
          </Grid>
          <Grid item lg={6}>
            <CustomDatePicker source="end_date" label="Fin de séjour" disablePast />
          </Grid>
          <Grid item lg={6}>
            <SelectInput source="capacity" label="Nb pers." choices={capacities} />
          </Grid>
          <Grid item lg={12}>
            <AutocompleteInput
              source="park_id"
              label="Lieu du séjour"
              validate={[required()]}
              choices={parkList}
              options={{ fullWidth: true }}
              allowEmpty
            />
          </Grid>
          <Grid item lg={12}>
            <AutocompleteInput
              source="park_id_second_choice"
              label="Autre choix en cas de non disponibilité"
              choices={parkList}
              options={{ fullWidth: true }}
              allowEmpty
            />
          </Grid>
          <Grid item lg={12}>
            <SelectInput source="mh_type" label="Type de Mobil-Home" choices={MHTypes} />
          </Grid>
          <Grid item lg={12}>
            <TextInput source="comments" label="Précision sur l'hébergement souhaité" multiline={true} fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <BooleanInput label="Côte à côte" source="is_cote_a_cote" />
          </Grid>
          <Grid item lg={6}>
            <TextInput label="Côte à côte de qui" source="cote_a_cote" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4}>
        <Grid item container direction="row" wrap="wrap" spacing={1}>
          <Grid item lg={6}>
            <RadioButtonGroupInput
              source="request_processed"
              label="Statut de la fiche"
              choices={requestProcessed}
              options={{
                style: {
                  color: 'red',
                },
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <SelectInput source="provenance" label="Provenance" choices={provenanceList} />
          </Grid>
          <Grid item lg={6}>
            <SelectInput source="processed_by" label="Demande traitée par" validate={[required()]} choices={preResaUsers} />
          </Grid>
          <Grid item lg={6}>
            <CustomDateTimePicker
              source="call_back_at"
              label="A rappeler le"
              disablePast
              options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
            />
          </Grid>
          <Grid item lg={6}>
            <DateHourFieldDisplay source="created_at" label="Ajoutée le" />
          </Grid>
          <Grid item lg={6}>
            <DateHourFieldDisplay source="updated_at" label="Dernière modification" />
          </Grid>
          <Grid item lg={12}>
            <TextInput source="hv_comments" label="Commentaires" multiline={true} fullWidth={true} />
          </Grid>
          <Grid item lg={6}>
            <RadioButtonGroupInput source="request_state" label="Etat de la demande" choices={requestStates} />
          </Grid>
          <Grid item lg={6}>
            <SelectInput source="reason" label="Raison du refus" allowEmpty choices={reasonList} />
          </Grid>
          <Grid item lg={6}>
            <TextInput source="partenaire" label="Partenaire" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

const useFormStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

// Formulaire d'edition
export const PreResaEdit = props => {
  const classes = useFormStyles()

  return (
    <Edit title={<PreResaTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <MyForm classes={classes} />
      </SimpleForm>
    </Edit>
  )
}

export const PreResaCreate = props => {
  const classes = useFormStyles()

  return (
    <Create title={<PreResaTitle />} {...props}>
      <SimpleForm>
        <MyForm classes={classes} />
      </SimpleForm>
    </Create>
  )
}

const EditToolbar = () => (
  <Toolbar>
    <SaveButton label="hv.action.save" redirect="list" submitOnEnter={true} />
    <SaveButton label="hv.action.save_and_edit" redirect={false} submitOnEnter={false} variant="contained" color="secondary" />
  </Toolbar>
)
