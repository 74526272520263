import React, { Component } from 'react'
import { Labeled, TextInput } from 'react-admin'
import moment from 'moment'

class renderDateHourField extends Component {
  render() {
    const { input, label, format } = this.props
    return (
      <Labeled label={label}>
        <span>
          {input.value
            ? moment
                .utc(input.value)
                .local()
                .format(format ? format : 'YYYY-MM-DD HH:mm:ss')
            : ''}
        </span>
      </Labeled>
    )
  }
}
export const DateHourFieldDisplay = ({ source, label, format }) => (
  <TextInput name={source} component={renderDateHourField} label={label} format={format} disabled />
)
